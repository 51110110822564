<template>
  <Panel :header="titleName" :toggleable="false">
    <template #icons v-if="this.$store.getters.getPageData.editMode">
      <button class="p-panel-header-icon link mr-2 handle">
        <span class="pi pi-bars"></span>
      </button>
      <button class="p-panel-header-icon link mr-2" @click="toggle">
        <span class="pi pi-ellipsis-h"></span>
      </button>
      <Menu :id="'boxMenu_'" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
    </template>

    <div class="no-drag" v-for="param in nodeElements" :key="param._id" :item="param">
      <div v-if="nodeEntry(param._id).metadata.name" class="grid">
        <div class="col-9 text-left">
          <p class="pl-1" v-tooltip.left="nodeEntry(param._id).metadata.description">
            {{ nodeEntry(param._id).metadata.name }}
          </p>
        </div>
        <div class="col-3 text-left">
          <!-- <transition name="pulse" mode="out-in"> -->
            <template v-if="nodeEntry(param._id).value.value > 25">
            <p style="color: red" :key="nodeEntry(param._id).value.value">
              {{ nodeEntry(param._id).value.value }}<span class="text-small">{{ nodeEntry(param._id).value.unit }}</span>
            </p>
            </template>
            <template v-else>
            <p v-tooltip.left="nodeEntry(param._id).timestamp + '\n' + nodeEntry(param._id)._id " :key="nodeEntry(param._id).value.value">
              {{ nodeEntry(param._id).value.value }}<span class="text-small">{{ nodeEntry(param._id).value.unit }}</span>
            </p>
            </template>
          <!-- </transition> -->
        </div>
        <Divider />
      </div>
    </div>

  </Panel>
  <div v-if="entryDialog === true">
    <dialog-io-multi v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData" @entrySave="
      ($value) => {
        changeVal($value);
      }
    " @entryAbort="hideDialog()"></dialog-io-multi>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";

export default defineComponent({
  name: "dashboardPanelValues",
  setup() { },
  emits: ["entrySave", "entryDuplicate", "entryDelete"],
  props: {
    content: {
      type: null,
      required: false,
    },
    dragObj: {
      type: null,
      required: false,
    },
    valueObj: {
      type: null,
      required: true,
    },
    dataObj: {
      type: null,
      required: false,
    },
    styleObj: {
      type: null,
      required: false,
    },
    readyLayout: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    // nodeEntry: {},
  },
  watch: {
    readyLayout() {
      this.watchLayout();
    },
  },
  computed: {
    titleName: function () {
      if (this.dragObj !== undefined) {
        return this.dragObj.label;
      } else {
        return "Werte Ansicht";
      }
    },
    // nodeEntry: function () {
    //   let arr = [];
    //   if (this.dataObj !== undefined) {
    //     this.dataObj.forEach((element) => {
    //       store.dispatch("getValues", element.id);
    //       arr.push(store.getters.getValue(element.id));
    //       // arr.push(store.dispatch("getValues", element.id));
    //       // console.log(store.getters.getValue(element.id));
    //     });
    //     return arr;
    //   } else return undefined;
    // },
    nodeEntry: function () {
      return (id) => store.getters.getValue(id);
    },
    nodeEntries: function () {
      return store.getters.getValues;
    },
    nodeElements: function () {
      return this.nodes;
    }
  },
  data() {
    return {
      entryDialog: false,
      ioDialogData: {},
      nodes: [],
      ioObj: [],
      items: [
        {
          label: "Einstellungen",
          icon: "pi pi-cog",
          command: () => {
            this.editEntry(this.dataObj);
          },
        },
        {
          label: "Duplizieren",
          icon: "pi pi-clone",
          command: () => {
            this.duplicateEntry();
          },
        },
        {
          label: "Löschen",
          icon: "pi pi-times",
          command: () => {
            this.deleteEntry();
          },
        },
      ],
    };
  },
  methods: {
    loadingState(state) {
      if (state) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    watchLayout() {
      if (this.readyLayout) {
        console.log("READY LAYOUT!!!!!!!!");
        // this.getNodes();
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
      // this.getParameterStatusIOTree();
      // this.getOPCAppTree();
    },
    hideDialog() {
      this.entryDialog = false;
      this.submitted = false;
      // clearInterval(this.intval_Value);
      // this.getUserList();
    },
    editEntry(ioData) {
      // this.ioDialogData = { ...ioData };
      // this.getUserList();
      const _obj = JSON.parse(JSON.stringify(ioData));
      for (let i = 0; i < _obj.length; i++) {
        this.ioObj[i] = store.getters.getValue(_obj[i].id);
      }
      // this.ioObj = store.getters.getValue(_obj.data.id);
      // const _obj = { ...ioData };
      this.ioDialogData = { ...this.content };
      // this.selectedKey[_obj.data.id] = true;

      console.log(this.ioDialogData);
      console.log(this.ioObj);

      // this.expandedKeys[this.nodes[0].key] = true;

      this.entryDialog = true;
      // this.intval_Value = setInterval(this.updateOpenValue, 1000);
    },
    changeVal(ioData) {
      this.entryDialog = false;
      this.submitted = false;
      this.$emit("entrySave", ioData);
      this.nodes = [];
      this.getNodes();
      this.ioDialogData = ioData;
    },
    duplicateEntry() {
      this.$emit("entryDuplicate", this.content);
      // this.resetDialogData();
    },
    deleteEntry() {
      this.$emit("entryDelete", this.content);
      // this.resetDialogData();
    },
    getNodes() {
      // let arr = [];
      if (this.dataObj !== undefined) {
        this.nodes = [];
        this.dataObj.forEach((element) => {
          // store.dispatch("getValues", element.id);
          this.nodes.push(store.getters.getValue(element.id));
          // arr.push(store.dispatch("getValues", element.id));
          // console.log("Registered node: ", store.getters.getValue(element.id));
        });
      } else return undefined;
    },
    getValue(_id) {
      var index = this.valueObj.findIndex((x) => x._id === _id);
      if (index === -1) {
        // this.values.push(vars);
        console.log("NOT FOUND");
      } else {
        // this.values[index] = vars;
        return this.valueObj[index];
      }
    },
  },
  mounted() {
    // console.log("PANEL MOUNTED");
    this.getNodes();
  },
  created() {
  },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
  // transition: opacity .5s;
}

.bounce-leave-active {
  animation: bounce-out 0.5s;
  // transition: opacity .5s;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
  {
  transform: translateX(10px);
  opacity: 0;
}

.pulse-enter-active {
  animation: pulse 1s;
}

.pulse-leave-active {
  animation: pulse 0.5s;
}

@keyframes pulse {
  0% {
    // transform: scale(1); /* scaling to 0 */
    opacity: 0.8;
    // color: yellowgreen;
    // transform-origin: left center;
    // transform: scaleY(.9);
  }

  50% {
    // transform: scale(1.2); /* increasing the size */
  }

  100% {
    // transform: scale(1); /* seeting back to initial size */
    opacity: 1;
  }
}

.grid {
  margin: 0 -0.5em 0 -0.5em !important;
}

.col-3,
.col-9 {
  // line-height: 1.4em !important;
  margin: 0 !important;
  padding: 0 !important;
}

.p-divider {
  margin: 0 !important;
  padding: 0 !important;
}
</style>