<template>
    <div class="card box widget-social pt-2 pb-2">
        <transition name="list" tag="div">
            <div>
                <ProgressSpinner v-if="loading" style="width: 50px; height: 50px" strokeWidth="4" fill="var(--red-600)"
                    animationDuration="1s" />
            </div>
        </transition>
        <OverlayPanel ref="op" appendTo="body" @show="focusElement">
            <div class="formgrid grid p-1" @keydown.esc="hideOP">
                <div class="field">
                    <h5>{{ currentGrp.enable.label }}</h5>
                    <div class="field">
                        <label class="mr-2">Ventil-Laufzeit</label>
                        <InputMask ref="timeInput" mask="99:99:99" placeholder="00:00:00" slotChar="hh:mm:ss"
                            v-model="this.currentGrp.timeSet.value" style="font-size: 1.2em" @keydown.enter="saveOP"
                            autofocus />
                    </div>
                    <div class="field-checkbox col justify-content-center">
                        <InputSwitch v-model="currentGrp.enable.value" /><span class="ml-2">Ventilprogramm <strong
                                class="fg-yellow underline">{{ currentGrp.enable.label }}</strong> freigeben</span>
                    </div>
                    <div class="grid justify-content-center">
                        <Button icon="pi pi-times" class="mt-5 mr-3" label="Abbrechen" @click="hideOP" />
                        <Button icon="pi pi-check" class="mt-5" label="Speichern" @click="saveOP" />
                    </div>
                </div>
            </div>
        </OverlayPanel>
        <div class="menu-overlay">
            <Button v-if="this.$store.getters.getPageData.editMode" icon="pi pi-bars"
                class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber handle" title="Bewegen"></Button>
            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-link mr-1 mt-1 fg-lightAmber" title="Menü"
                @click="toggle"></Button>
            <Menu :id="'boxMenu_' + 1" ref="menu" :model="items" :popup="true" :baseZIndex="100" />
        </div>
        <div v-if="!loading">
            <div class="flex justify-content-between align-items-center p-1">
                <div class="hidden md:inline-flex social-icon">
                    <i :class="icon + ' fs-xxlarge'"></i>
                </div>
                <div class="info flex flex-column">
                    <!-- <span class="value">{{ value }}</span> -->
                    <h1 :key="value" class="inline-block mb-0 mt-0">
                        {{ label }}
                    </h1>
                    <span class="subtext mt-1">{{ department }}</span>
                    <span class="subtext mt-1">{{ description }}</span>
                </div>
            </div>

            <div class="stats flex justify-content-center mt-1">
                <div class="flex flex-column">
                    <div class="flex mt-3">
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Badge class="cursor-pointer" :class="enableGrp1.value ? 'bg-lightGreen' : 'bg-red'" value="BEW"
                                @click="toggleOP($event, 1)"></Badge>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Badge class="cursor-pointer" :class="enableGrp2.value ? 'bg-lightGreen' : 'bg-red'" value="SPÜ"
                                @click="toggleOP($event, 2)"></Badge>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center mr-3">
                            <Badge class="cursor-pointer" :class="enableGrp3.value ? 'bg-lightGreen' : 'bg-red'" value="DÜN"
                                @click="toggleOP($event, 3)"></Badge>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center">
                            <Badge class="cursor-pointer" :class="enableGrp4.value ? 'bg-lightGreen' : 'bg-red'" value="SPE"
                                @click="toggleOP($event, 4)"></Badge>
                        </div>
                    </div>
                    <div class="flex mt-1">
                        <div class="flex-1 align-items-center justify-content-center fg-darkBlue mr-3">
                            <small>{{ timeGrp1.value }}</small>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center fg-olive mr-3">
                            <small>{{ timeGrp2.value }}</small>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center fg-darkAmber mr-3">
                            <small>{{ timeGrp3.value }}</small>
                        </div>
                        <div class="flex-1 align-items-center justify-content-center fg-magenta">
                            <small>{{ timeGrp4.value }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="entryDialog === true">
            <dialog-io-single v-bind:showDialog="entryDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideDialog()"></dialog-io-single>
        </div>
        <div v-if="valveDialog === true">
            <dialog-valve v-bind:showDialog="valveDialog" :dialogObj="ioObj" v-bind:dialogData="ioDialogData"
                @entrySave="
                    ($value) => {
                        changeVal($value);
                    }
                " @entryAbort="hideValveDialog()"></dialog-valve>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import store from '@/store';
import { findIndexByValue, findIndexByDisplayName, hms, timeStringToInt } from '../helpers';
import SocketioService from '@/services/socketioService.js';

export default defineComponent({
    name: 'dashboardValveBox',
    setup() { },
    props: {
        content: {
            type: null,
            required: true,
        },
        dragObj: {
            type: null,
            required: false,
        },
        valueObj: {
            type: null,
            required: false,
        },
        dataObj: {
            type: null,
            required: false,
        },
        styleObj: {
            type: null,
            required: false,
        },
        readyLayout: {
            type: Boolean,
            required: false,
        },
    },
    watch: {
        readyLayout() {
            this.watchLayout();
        },
        '$store.getters.getPageData.editMode': function () {
            this.checkEditMode();
        },
    },
    computed: {
        componentName: function () {
            if (this.content.name !== undefined) {
                return this.content.name;
            } else {
                return '???';
            }
        },
        componentLabel: function () {
            if (this.content.label !== undefined) {
                return this.content.label;
            } else {
                return '???';
            }
        },
        componentContent: function () {
            if (this.content.content !== undefined) {
                return this.content.content;
            } else {
                return '???';
            }
        },
        nodeEntry: function () {
            if (this.content.data.id) {
                return store.getters.getValue(this.content.data.id);
            } else return undefined;
        },
        label: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.name;
            } else {
                return this.content.data.label;
            }
        },
        description: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.metadata.description;
            } else {
                return this.content.data.label;
            }
        },
        department: function () {
            if (this.nodeEntry !== undefined && store.getters.getConfigIo.types_department.length) {
                const index = findIndexByValue(store.getters.getConfigIo.types_department, this.nodeEntry.metadata.department);
                // return this.nodeEntry.metadata.department;
                if (index !== -1) {
                    return this.nodeEntry.metadata.department + ' - ' + store.getters.getConfigIo.types_department[index].label;
                } else {
                    return '👻';
                }
            } else {
                return this.content.data.department;
            }
        },
        value: function () {
            if (this.nodeEntry !== undefined) {
                if (this.nodeEntry.value.value === '🤷🏼‍♂️') {
                    this.loadingState(true);
                } else {
                    this.loadingState(false);
                }
                if (this.nodeEntry.value.unit === 'boolean') {
                    if (this.nodeEntry.value.value > 0) {
                        return 'Ja';
                    } else {
                        return 'Nein';
                    }
                }
                return this.nodeEntry.value.value;
            } else {
                return this.content.data.value;
            }
        },
        unit: function () {
            if (this.nodeEntry !== undefined) {
                if (this.nodeEntry.value.unit === 'boolean') {
                    return '';
                }
                return this.nodeEntry.value.unit;
            } else {
                return this.content.data.unit;
            }
        },
        valueAlt: function () {
            if (this.nodeEntry !== undefined) {
                // if (this.nodeEntry.value.unit === "boolean") {
                //   if (this.nodeEntry.value.value > 0) {
                //     return "Ein";
                //   } else {
                //     return "Aus";
                //   }
                // }
                if (this.nodeEntry.value.unit === '°') {
                    const degree = this.nodeEntry.value.value;
                    if ((degree >= 349 && degree <= 360) || (degree >= 0 && degree < 12)) return 'N';
                    if (degree >= 12 && degree < 34) return 'NNO';
                    if (degree >= 34 && degree < 56) return 'NO';
                    if (degree >= 56 && degree < 79) return 'ONO';
                    if (degree >= 79 && degree < 101) return 'O';
                    if (degree >= 101 && degree < 124) return 'OSO';
                    if (degree >= 124 && degree < 146) return 'SO';
                    if (degree >= 146 && degree < 169) return 'SSO';
                    if (degree >= 169 && degree < 191) return 'S';
                    if (degree >= 191 && degree < 213) return 'SSW';
                    if (degree >= 213 && degree < 236) return 'SW';
                    if (degree >= 236 && degree < 259) return 'WSW';
                    if (degree >= 259 && degree < 281) return 'W';
                    if (degree >= 281 && degree < 304) return 'WNW';
                    if (degree >= 304 && degree < 326) return 'NW';
                    if (degree >= 326 && degree < 349) return 'NNW';
                }
                return undefined;
            } else {
                return undefined;
            }
        },
        unitAlt: function () {
            if (this.nodeEntry !== undefined) {
                if (this.nodeEntry.value.unit === 'boolean') {
                    return '';
                }
                return this.nodeEntry.value.unit;
            } else {
                return this.content.data.unit;
            }
        },
        timestamp: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry.timestamp;
            } else {
                return this.content.data.timestamp;
            }
        },
        _id: function () {
            if (this.nodeEntry !== undefined) {
                return this.nodeEntry._id;
            } else {
                return this.content.data._id;
            }
        },
        icon: function () {
            if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
                let iCol;
                const index = findIndexByDisplayName(this.nodeEntry.children, 'Valve');
                if (this.nodeEntry.children[index].value > 0) {
                    iCol = ' fg-green pulse-loop';
                } else {
                    iCol = ' fg-red';
                }
                return this.nodeEntry.metadata.icon + iCol;
            } else {
                return 'mif-blocked';
            }
        },
        nodes: function () {
            return store.getters.getIoTree;
        },
        colors: function () {
            return store.getters.getStyleColors;
        },
        patterns: function () {
            return store.getters.getStylePatterns;
        },
        timeGrp1: function () {
            const child = {...this.returnChild('Group01Time')};
            child.value = hms(child.value);
            return child;
        },
        enableGrp1: function () {
            const child =  {...this.returnChild('Group01')};
            return child;
        },
        timeGrp2: function () {
            const child = {...this.returnChild('Group02Time')};
            child.value = hms(child.value);
            return child;
        },
        enableGrp2: function () {
            const child =  {...this.returnChild('Group02')};
            return child;
        },
        timeGrp3: function () {
            const child = {...this.returnChild('Group03Time')};
            child.value = hms(child.value);
            return child;
        },
        enableGrp3: function () {
            const child =  {...this.returnChild('Group03')};
            return child;
        },
        timeGrp4: function () {
            const child = {...this.returnChild('Group04Time')};
            child.value = hms(child.value);
            return child;
        },
        enableGrp4: function () {
            const child =  {...this.returnChild('Group04')};
            return child;
        },
    },
    data() {
        return {
            loading: false,
            entryDialog: false,
            valveDialog: false,
            ioDialogData: {},
            ioObj: {},
            timeSetGrp: null,
            currentGrp: {
                enable: null,
                timeSet: null
            },
            submitted: false,
            selectedKey: {},
            expandedKeys: {},
            items: [],
            items1: [
                {
                    label: 'Handstart',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Ventileinstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editValve(this.content);
                    },
                },
            ],
            items2: [
                {
                    separator: true,
                },
                {
                    label: 'Einstellungen',
                    icon: 'pi pi-cog',
                    command: () => {
                        this.editEntry(this.content);
                    },
                },
                {
                    label: 'Duplizieren',
                    icon: 'pi pi-clone',
                    command: () => {
                        this.duplicateEntry();
                    },
                },
                {
                    label: 'Löschen',
                    icon: 'pi pi-times',
                    command: () => {
                        this.deleteEntry();
                    },
                },
            ],
        };
    },
    mounted() {
        this.checkEditMode();
        // let { diff: storeADiff, totalValue: storeATotalValue, status: storeAStatus } = this.calculateStore(this.storeAData, this.storeATotalValue);
        //               this.storeADiff = storeADiff;
        //               this.storeATotalValue = storeATotalValue;
        //               this.storeAStatus = storeAStatus;
        // this.$refs.storeA.chart.update();
        // console.log("BOX MOUNTED");
        // if (this.dataObj.id) {
        //   this.nodeEntry = store.getters.getValue(this.dataObj.id);
        // }
    },
    methods: {
        loadingState(state) {
            if (state) {
                this.loading = true;
            } else {
                this.loading = false;
            }
        },
        watchLayout() {
            if (this.readyLayout) {
                // this.getNodes();
            }
        },
        checkEditMode() {
            if (this.$store.getters.getPageData.editMode) {
                this.items = [...this.items1, ...this.items2];
            } else {
                this.items = [...this.items1];
            }
        },
        returnChild(childStr) {
            let result = {};
            if (this.nodeEntry !== undefined && this.nodeEntry.children !== undefined) {
                const index = findIndexByDisplayName(this.nodeEntry.children, childStr);
                if (index !== -1) {
                    // const val = this.nodeEntry.children[index].value;
                    // return val;
                    result = this.nodeEntry.children[index];
                    return result;
                } else return;
            } else {
                result.value = '🤷🏼‍♂️';
                result.label = '🤷🏼‍♂️';
                result.key = '🤷🏼‍♂️';
                result.unit = '🤷🏼‍♂️';
                result.factor = 1;
                result.type = '🤷🏼‍♂️';
                result.icon = '🤷🏼‍♂️';
                return ;
            }
        },
        resetCurrentGrp() {
            this.currentGrp = {
                name: null,
                label: null,
                enable: null,
                timeSet: null,
                limits: {},
                controller: {},
            }
        },
        timeDialog(time) {
            console.log(time);
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
            // this.getParameterStatusIOTree();
            // this.getOPCAppTree();
        },
        toggleOP(event, grp) {
            if (grp === 1) {
                this.currentGrp.enable = this.enableGrp1;
                this.currentGrp.timeSet = this.timeGrp1;
            }
            if (grp === 2) {
                this.currentGrp.enable = this.enableGrp2;
                this.currentGrp.timeSet = this.timeGrp2;
            }
            if (grp === 3) {
                this.currentGrp.enable = this.enableGrp3;
                this.currentGrp.timeSet = this.timeGrp3;
            }
            if (grp === 4) {
                this.currentGrp.enable = this.enableGrp4;
                this.currentGrp.timeSet = this.timeGrp4;
            }
            this.$refs.op.toggle(event);
        },
        hideOP() {
            this.currentGrp.timeSet = null;
            // this.currentGrp = { name: null, label: null, enable: null, timeSet: null };
            this.currentGrp = { enable: null, timeSet: null };
            this.$refs.op.hide();
        },
        saveOP(event) {
            let childElements = [];
            Object.entries(this.currentGrp).forEach(([, value]) => {
                let returnObj = {};
                returnObj.displayName = value.key;
                returnObj.value = {
                    unit: value.unit,
                    factor: value.factor,
                    label: value.label,
                    key: value.key,
                    value: null
                }
                // check if parameter is a time parameter (H:M:S) style
                if (value.unit === 'HMS-Convert') {
                    returnObj.value.value = timeStringToInt(value.value);
                }
                else if (value.unit === 'boolean') {
                    if (value.value) {
                        returnObj.value.value = true;
                    } else {
                        returnObj.value.value = false;
                    }
                }
                else {
                    returnObj.value.value = parseInt(value.value * value.factor);
                }
                childElements.push(returnObj);
            });

            let nodesToWrite = [];

            childElements.forEach((element) => {
                if (!isNaN(element.value.value)) {
                    const node = {
                        srv: this.nodeEntry.source.server,
                        path: this.nodeEntry.source.path + '.' + element.displayName,
                        value: element.value,
                        datatype: 5,
                        metadata: this.nodeEntry.metadata,
                        dataTypeIo: this.nodeEntry.value.datatype
                    }
                    nodesToWrite.push(node)
                }
            });

            if (nodesToWrite.length > 0) {
                SocketioService.setServerData('writeOpc', 'value', nodesToWrite, (err, response) => {
                    // console.log(response);
                    // this.getMenuList();
                    if (!err) {
                        if (response !== null) {
                            if (response === 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderung erfolgreich', detail: 'Wert wurde erfolgreich geändert', life: 1500 });
                            } else if (response > 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderungen erfolgreich', detail: response + ' Werte wurden erfolgreich geändert', life: 1500 });
                            } else {
                                this.$toast.add({ severity: 'info', summary: 'Änderung nicht notwendig', detail: 'Einstellwerte sind ident', life: 1500 });
                            }
                        }
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Fehler bei Änderung', detail: err + ' - ' + event, life: 1500 });
                    }
                });
            }
            this.hideOP();
        },
        saveOP_(event) {
            console.log(this.currentGrp.timeSet);
            // let retObj = JSON.parse(JSON.stringify(this.nodeEntry));
            let value = timeStringToInt(this.currentGrp.timeSet);
            // let currGrp = this.currentGrp;
            // let index = findIndexByDisplayName(retObj.children, currGrp.name + 'Time');
            let nodesToWrite = [];

            const dataTime = {
                srv: this.nodeEntry.source.server,
                path: this.nodeEntry.source.path + '.' + this.currentGrp.name + 'Time',
                value: value,
                datatype: 7,
                metadata: this.nodeEntry.metadata,
                dataTypeIo: this.nodeEntry.value.datatype
            }
            nodesToWrite.push(dataTime);

            value = this.currentGrp.enable;

            const dataSwitch = {
                srv: this.nodeEntry.source.server,
                path: this.nodeEntry.source.path + '.' + this.currentGrp.name,
                value: value,
                datatype: 1,
                metadata: this.nodeEntry.metadata,
                dataTypeIo: this.nodeEntry.value.datatype
            }
            nodesToWrite.push(dataSwitch);

            console.log(dataTime.value, dataSwitch.value);

            // if (dataTime.value !== retObj.children[index].value) {
            if (nodesToWrite.length > 0) {
                SocketioService.setServerData('writeOpc', 'value', nodesToWrite, (err, response) => {
                    if (!err) {
                        if (response !== null) {
                            if (response === 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderung erfolgreich', detail: 'Wert wurde erfolgreich geändert', life: 1500 });
                            } else if (response > 1) {
                                this.$toast.add({ severity: 'success', summary: 'Änderungen erfolgreich', detail: response + ' Werte wurden erfolgreich geändert', life: 1500 });
                            } else {
                                this.$toast.add({ severity: 'info', summary: 'Änderung nicht notwendig', detail: 'Einstellwerte sind ident', life: 1500 });
                            }
                        }
                    } else {
                        this.$toast.add({ severity: 'error', summary: 'Fehler bei Änderung', detail: err + ' - ' + event, life: 1500 });
                    }
                });
            }


            // index = findIndexByDisplayName(retObj.children, currGrp.name);

            // if (dataSwitch.value !== retObj.children[index].value) {
            //     SocketioService.setServerData('writeOpc', 'value', dataSwitch, (err, response) => {
            //         console.log(response);
            //         // this.getMenuList();
            //         if (!err) {
            //             if (response !== null) {
            //                 // // const index = findIndexByDisplayName(retObj.children, currGrp.name);
            //                 // if (index !== -1) {
            //                 //     retObj.children[index].value = dataSwitch.value;
            //                 // }
            //                 // console.log(retObj);
            //                 // this.$store.dispatch('setValues', retObj);
            //                 this.$toast.add({ severity: 'success', summary: 'Ventil-Programm geändert', detail: event + response.value, life: 1000 });
            //             }
            //         } else {
            //             this.$toast.add({ severity: 'error', summary: 'Ventil-Programm nicht geändert', detail: event + this.currentGrp.enable, life: 1000 });
            //         }
            //     });
            // }
            this.hideOP();
        },
        focusElement() {
            this.$refs.timeInput.$el.focus();
        },
        hideDialog() {
            this.entryDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
        resetDialogData() {
            this.ioDialogData = {
                style: {},
                components: {},
                data: {},
            };
            this.selectedKey = {};
            this.expandedKeys = {};
        },
        changeVal(ioData) {
            // console.log(ioData);
            this.$emit('entrySave', ioData);
        },
        editEntry(ioData) {
            // this.ioDialogData = { ...ioData };
            // this.getUserList();
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioObj = store.getters.getValue(_obj.data.id);
            // const _obj = { ...ioData };
            this.ioDialogData = _obj;
            // this.selectedKey[_obj.data.id] = true;

            console.log(this.nodes);

            for (let node of this.nodes) {
                if (node.key === this.ioObj.metadata.category) {
                    this.expandNode(node);
                }
            }

            // this.expandedKeys[this.nodes[0].key] = true;

            this.entryDialog = true;
            // this.intval_Value = setInterval(this.updateOpenValue, 1000);
        },
        saveDialog() {
            this.submitted = true;

            if (this.ioDialogData.i) {
                // let _index = this.findIndexById(this.ioDialogData._id);
                // if (_index >= 0) {
                //   this.system_user[_index] = this.ioDialogData;
                //   // SocketioService.setUserList(this.system_user[_index]);
                // } else {
                //   // SocketioService.setUserList(this.ioDialogData);
                // }
                // this.content = {...this.ioDialogData};
                this.$emit('entrySave', this.ioDialogData);
                this.entryDialog = false;
                this.resetDialogData();
            }
        },
        duplicateEntry() {
            this.$emit('entryDuplicate', this.content);
            this.resetDialogData();
        },
        deleteEntry() {
            this.$emit('entryDelete', this.content);
            this.resetDialogData();
        },
        expandNode(node) {
            this.expandedKeys[node.key] = true;
            if (node.children && node.children.length) {
                for (let child of node.children) {
                    this.expandNode(child);
                    if (child.key === this.ioObj._id) {
                        this.selectedKey[this.ioObj._id] = true;
                    }
                }
            }
        },
        onNodeSelect(node) {
            const newObj = store.getters.getValue(node.key);
            this.selectedKey = node.key;
            this.ioDialogData.data.id = node.key;

            this.$toast.add({
                severity: 'success',
                summary: 'Node ausgewählt',
                detail: newObj.metadata.name + '\n' + this.selectedKey + '\n' + newObj.nodeId,
                life: 3000,
            });
        },
        editValve(ioData) {
            // this.ioDialogData = { ...ioData };
            // this.getUserList();
            const _obj = JSON.parse(JSON.stringify(ioData));
            this.ioObj = store.getters.getValue(_obj.data.id);
            // const _obj = { ...ioData };
            this.ioDialogData = _obj;
            // this.selectedKey[_obj.data.id] = true;

            console.log(this.nodes);

            for (let node of this.nodes) {
                if (node.key === this.ioObj.metadata.category) {
                    this.expandNode(node);
                }
            }

            // this.expandedKeys[this.nodes[0].key] = true;

            this.valveDialog = true;
            // this.intval_Value = setInterval(this.updateOpenValue, 1000);
        },
        hideValveDialog() {
            this.valveDialog = false;
            this.submitted = false;
            // clearInterval(this.intval_Value);
            // this.getUserList();
        },
    },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
    // transition: opacity .5s;
}

.bounce-leave-active {
    animation: bounce-out 0.5s;
    // transition: opacity .5s;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active for <2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.pulse-enter-active {
    animation: pulse 1s;
}

.pulse-leave-active {
    animation: pulse 0.5s;
}

.pulse-loop {
    animation: pulse 1s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        // transform: scale(1); /* scaling to 0 */
        opacity: 0.8;
        // color: yellowgreen;
        // transform-origin: center center;
        // transform: scaleY(.9);
        text-shadow: black 2px 0 10px;
        transform: scale(0.9);
        /* increasing the size */
    }

    30% {
        transform: scale(1.1);
        /* increasing the size */
        text-shadow: gray 1px 0 10px;
    }

    100% {
        transform: scale(1);
        /* seeting back to initial size */
        opacity: 1;
    }
}

.grid {
    margin: 0 -0.5em 0 -0.5em !important;
}

.col {
    // line-height: 1.4em !important;
    margin: 0 !important;
    padding: 0 !important;
}

.p-divider {
    margin: 0 !important;
    padding: 0 !important;
}

.box {
    overflow: hidden;
    // text-shadow: 1px 1px 1px black;
}

.card {
    font-family: 'SensorFont';
    line-height: 1.3rem;
    position: relative;
}

.text-normal {
    line-height: 0;
}

.menu-overlay {
    top: 0px;
    right: 0px;
    position: absolute;
    height: 100px;
    opacity: 0;
    transition: 0.2s ease;
    background: none;
    text-align: right;
}

.card:hover .menu-overlay {
    opacity: 1;
}

.menu-overlay .p-button-rounded {
    width: 2rem;
    height: 2rem;
}

// .list-enter-active,
// .list-leave-active {
//   transition: all 0.5s ease;
//   transform: scale(0.7); /* increasing the size */
// }
// .list-enter-from,
// .list-leave-to {
//   opacity: 0;
//   transform: scale(1); /* increasing the size */
// }
.list-enter-active {
    animation: list 1s;
}

.list-leave-active {
    animation: list 0.5s;
}

@keyframes list {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>