<template>
  <Panel :header="titleName" :toggleable="true">
    <template #icons>
      <button class="p-panel-header-icon p-link p-mr-2 handle">
        <span class="pi pi-bars"></span>
      </button>
      <button class="p-panel-header-icon p-link p-mr-2" @click="toggle">
        <span class="pi pi-cog"></span>
      </button>
      <Menu id="config_menu" ref="menu" :model="items" :popup="true" />
    </template>
    <div v-for="param in dataObj" :key="param.id" :item="param">
      <div v-if="param.label" class="p-grid">
        <div class="p-col text-left">
          <p>{{ param.label }}</p>
        </div>
        <div class="p-col text-left">
          <!-- <transition name="pulse" mode="out-in"> -->
          <p style="color:red;" v-if="param.value.value > 25" :key="param.value.value">
            {{ param.value.value + " " + param.value.unit }}
          </p>
          <p v-else>
            {{ param.value.value + " " + param.value.unit }}
          </p>
          <!-- </transition> -->
        </div>
        <Divider />
      </div>
    </div>
  </Panel>
</template>

<script>
import { defineComponent } from "vue";
// import store from "@/store";

export default defineComponent({
  name: "dashboardValue",
  setup() {},
  props: {
    dragObj: {
      type: null,
      required: false,
    },
    valueObj: {
      type: null,
      required: true,
    },
    dataObj: {
      type: null,
      required: false
    }
  },
  computed: {
    titleName: function () {
      if (this.dragObj !== undefined) {
        return this.dragObj.label;
      } else {
        return "Werte Ansicht1";
      }
    },
  },
  data() {
    return {
      items: [
        {
          label: "Einstellungen",
          icon: "pi pi-refresh",
          command: () => {
            this.editNode(this.node);
          },
        },
        {
          label: "Löschen",
          icon: "pi pi-times",
          command: () => {
            this.$toast.add({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
      ],
    };
  },
  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
      // this.getParameterStatusIOTree();
      // this.getOPCAppTree();
    },
  },
});
</script>

<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
  // transition: opacity .5s;
}
.bounce-leave-active {
  animation: bounce-out 0.5s;
  // transition: opacity .5s;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.pulse-enter-active {
  animation: pulse 2s;
}
.pulse-leave-active {
  animation: pulse .5s;
}
@keyframes pulse {
  0% {
    // transform: scale(1); /* scaling to 0 */
    opacity: .8;
    // color: yellowgreen;
    // transform-origin: left center;
    // transform: scaleY(.9);
  }
  50% {
    // transform: scale(1.2); /* increasing the size */
  }
  100% {
    // transform: scale(1); /* seeting back to initial size */
    opacity: 1;
  }
}
.p-grid {
  margin: 0 -0.5em 0 -0.5em !important;
}
.p-col {
  // line-height: 1.4em !important;
  margin: 0 !important;
  padding: 0 !important;
}
.p-divider {
  margin: 0 !important;
  padding: 0 !important;
}
</style>